<template>
  <v-container class="services-wrap">
    <h2 v-if="title" class="services_title">{{ title }}</h2>

    <v-row dense>
      <v-col
        :cols="cols"
        class="pb-1 pt-1"
        v-for="service in activeButton"
        :key="service.page"
      >
        <service-button :service="service" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.services-wrap {
  padding: 0 12px;
  text-align: center;
}
</style>
<script>
import ServiceButton from "./ServiceButton";
export default {
  name: "ServiceButtons",
  props: {
    title: { type: String, required: false },
    disabledButtons: {
      type: Array,
      required: false,
      default: function() {
        return new Array(0);
      }
    }
  },
  components: { ServiceButton },
  data() {
    return {
      buttons: [
        {
          deliveryServiceId: 2,
          page: "iperal-a-casa-tua",
          icon: "/services-icons/consegna-a-casa-small.svg",
          name: "A casa tua"
        },
        {
          deliveryServiceId: 1,
          page: "iperal-ritiro-in-negozio",
          icon: "/services-icons/ritiro-in-negozio-small.svg",
          name: "Ritira in negozio"
        },
        {
          deliveryServiceId: 10002,
          page: "iperal-locker",
          icon: "/services-icons/locker-small.svg",
          name: "Locker"
        }
      ]
    };
  },
  computed: {
    cols() {
      return 12 / (this.buttons.length - this.disabledButtons.length);
    },
    activeButton() {
      var result = [];

      this.buttons.forEach(element => {
        if (!this.disabledButtons.includes(element.deliveryServiceId)) {
          result.push(element);
        }
      });
      return result;
    }
  }
};
</script>

<style></style>
