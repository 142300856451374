<template>
  <router-link
    :to="{ name: 'Page', params: { pageName: service.page } }"
    class="no-underline"
  >
    <div class="service">
      <div class="service__content d-flex align-center">
        <div class="service__content-text d-flex flex-column">
          <div class="service_content-img d-flex align-center">
            <img :src="service.icon" :alt="service.name" />
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<style scoped lang="scss">
.service {
  background: transparent
    linear-gradient(180deg, #ef7400 0%, #ff9800 0%, #e54907 92%, #d70913 100%)
    0% 0% no-repeat padding-box;
  border-radius: 20px 18px 18px 18px;
  height: 80px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;

  .service_content-img {
    img {
      height: 32px;
    }
    span {
      line-height: 1.1;
    }
    .claim {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 60px;
    .service_content-img {
      img {
        height: 22px;
      }
    }
  }
}
</style>
<script>
export default {
  name: "ServiceButtons",
  props: { service: { type: Object, required: true } }
};
</script>

<style></style>
