<template>
  <v-card rounded="lg" elevation="2" class="proposal-card-responsive">
    <v-img :src="proposal.img" height="100%">
      <div
        class="d-flex flex-row flex-sm-column justify-space-between card-content pa-2 align-center"
      >
        <div class="image flex-grow-1 align-self-center d-flex align-center">
          <img
            :alt="proposal.imgAlt"
            :src="proposal.metaData.category_proposal_type.IMAGE"
            class="overlayed"
          />
        </div>
        <div
          class="d-flex align-center pa-5 content"
          :class="{
            'flex-column':
              proposal.metaData.category_proposal_type.COLS < 2 ||
              $vuetify.breakpoint.xs
          }"
        >
          <div v-text="proposal.name" class=" proposal-name pa-3"></div>
          <v-btn
            outlined
            depressed
            x-large
            color="white"
            class="proposal-btn"
            :href="link"
            @click.prevent="handleLink"
          >
            {{ proposal.metaData.category_proposal_type.BTN_LABEL }}
          </v-btn>
        </div>
      </div>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.overlayed {
  max-width: 100%;
}
.proposal-card-responsive {
  .card-content {
    height: 100%;
    color: $white;
    .proposal-name {
      font-size: 24px;
      line-height: 1;
      text-align: left;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 18px;
      }
    }
    .v-btn {
      background: rgba($white, 0.2);
      height: 48px;
      .v-btn__content {
        font-size: 14px;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  height: 280px;
  @media #{map-get($display-breakpoints, 'sm-only')} {
    height: 280px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 180px;
    .image,
    .content {
      width: 50%;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";
export default {
  name: "ProposalCardResponsive",
  props: ["proposal"],
  mixins: [banner]
};
</script>
