var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.isAuthenticated)?_c('NewsAlert'):_vm._e(),(
      _vm.isAuthenticated && (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)
    )?_c('v-container',[_c('CartInfoSlider')],1):_vm._e(),(!_vm.isAuthenticated)?_c('ServiceButtons',{attrs:{"title":_vm.$t('ServicesButtons.titleHomePage')}}):_vm._e(),_c('h1',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"d-sr-only",domProps:{"textContent":_vm._s(_vm.description)}}),_c('v-container',{staticClass:"homepage-disclaimer pb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("homepage.priceDisclaimer")))])]),(_vm.carousel.length > 0)?_c('v-container',[(
        _vm.category.metaData.template_model &&
          _vm.category.metaData.template_model.CAROUSEL_ONE_TITLE
      )?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.category.metaData.template_model.CAROUSEL_ONE_TITLE)}}):_vm._e(),_c('ProposalSlider',{attrs:{"proposals":_vm.carousel}})],1):_vm._e(),(_vm.carouselTwo.length > 0)?_c('v-container',[(
        _vm.category.metaData.template_model &&
          _vm.category.metaData.template_model.CAROUSEL_TWO_TITLE
      )?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.category.metaData.template_model.CAROUSEL_TWO_TITLE)}}):_vm._e(),_c('ProposalSlider',{attrs:{"proposals":_vm.carouselTwo}})],1):_vm._e(),_vm._l((_vm.headerProducts),function(proposal){return _c('v-container',{key:proposal.id,staticClass:"pb-0"},[_c('ProductListSlider',{key:_vm.key,attrs:{"proposal":proposal,"title":proposal.descr,"paginationClass":'swiper-pagination' + proposal.id}})],1)}),(_vm.headerBanner.length > 0)?_c('v-container',{staticClass:"header-banner-container"},[(
        _vm.category.metaData.template_model &&
          _vm.category.metaData.template_model.IPERAL_PROPOSAL_TITLE
      )?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.category.metaData.template_model.IPERAL_PROPOSAL_TITLE)}}):_vm._e(),_c('ProposalIperalGrid',{attrs:{"proposals":_vm.headerBanner}})],1):_vm._e(),(_vm.sidebarBanner.length > 0 || _vm.footerBanner.length > 0)?_c('v-container',{staticClass:"sidebar-banner"},[(
        _vm.sidebarBanner.length > 0 &&
          _vm.category.metaData.template_model &&
          _vm.category.metaData.template_model.VENDOR_PROPOSAL_TITLE
      )?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.category.metaData.template_model.VENDOR_PROPOSAL_TITLE)}}):_vm._e(),(_vm.sidebarBanner.length > 0)?_c('ProposalBrandGrid',{attrs:{"proposals":_vm.sidebarBanner,"proposal-class":"brand"}}):_vm._e(),(
        _vm.footerBanner.length > 0 &&
          _vm.category.metaData.template_model &&
          _vm.category.metaData.template_model.FOOTER_PROPOSAL_TITLE
      )?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.category.metaData.template_model.FOOTER_PROPOSAL_TITLE)}}):_vm._e(),(_vm.footerBanner.length > 0)?_c('ProposalSlider',{attrs:{"paginationClass":'swiper-pagination-footer',"proposals":_vm.footerBanner}}):_vm._e()],1):_vm._e(),(_vm.footerProducts.length > 0)?_c('v-container',{key:_vm.key,staticClass:"footer-products"},[(
        _vm.footerProducts.length > 0 &&
          _vm.category.metaData.template_model &&
          _vm.category.metaData.template_model.FOOTER_BANNER_TITLE
      )?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.category.metaData.template_model.FOOTER_BANNER_TITLE)}}):_vm._e(),_vm._l((_vm.footerProducts),function(proposal){return _c('ProductListSlider',{key:proposal.id,staticClass:"py-2",attrs:{"proposal":proposal,"title":proposal.descr,"mode":proposal.metaData.category_proposal_type.MODE,"paginationClass":'swiper-pagination' + proposal.id}})})],2):_vm._e(),_c('v-container'),(_vm.isAuthenticated)?_c('ServiceButtons',{staticClass:"mb-5",attrs:{"title":_vm.$t('ServicesButtons.titleHomePage')}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }