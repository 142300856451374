<template>
  <div class="header-banner pt-0">
    <h2 v-html="title" v-if="title"></h2>
    <v-row>
      <v-col
        v-for="proposal in proposals"
        :key="proposal.id"
        :cols="12"
        :sm="
          proposal.metaData && proposal.metaData.category_proposal_type
            ? proposal.metaData.category_proposal_type.COLS * 6
            : 6
        "
        :md="
          proposal.metaData && proposal.metaData.category_proposal_type
            ? proposal.metaData.category_proposal_type.COLS * 3
            : 3
        "
      >
        <ProposalCardResponsive :proposal="proposal" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProposalCardResponsive from "./ProposalCardResponsive.vue";
export default {
  name: "ProposalIperalGrid",
  props: {
    title: { type: String, required: false },
    proposals: { type: Array, required: true }
  },
  components: { ProposalCardResponsive },
  data() {
    return {};
  }
};
</script>
