<template>
  <div class="cart-info-slider">
    <swiper :options="swiperOption" :slides-per-view="1">
      <swiper-slide>
        <CartInfoAddressCard
          color="white"
          :shippingAddress="cart.shippingAddress"
          :editable="editableAddress"
          @edit="openAddressSelector"
        />
      </swiper-slide>
      <swiper-slide>
        <CartInfoTimeslotCard
          color="white"
          :shippingAddress="cart.shippingAddress"
          :timeslot="cart.timeslot"
          :editable="editableTimeslot"
          @edit="openTimeslotSelector"
        />
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination-services" slot="pagination"></div>
  </div>
</template>

<script>
import CartInfoAddressCard from "./CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "./CartInfoTimeslotCard.vue";
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";

export default {
  name: "CartInfoSlider",
  components: { CartInfoAddressCard, CartInfoTimeslotCard },
  mixins: [cartInfo, login],
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination-services",
          clickable: true
        }
      }
    };
  }
};
</script>

<style lang="scss">
.cart-info-slider {
  .card-info-wrapper {
    background: transparent
      linear-gradient(180deg, #ef7400 0%, #ff9800 0%, #e54907 92%, #d70913 100%)
      0% 0% no-repeat padding-box;
    border-radius: 20px 18px 18px 18px !important;
    height: 56px;
    color: white;
    margin-bottom: 0;
    padding: 5px 15px;
    img {
      width: 40px;
      height: 40px;
    }
    .v-icon {
      font-size: 16px !important;
      height: 44px !important;
      width: 15px !important;
    }
    .card-info {
      line-height: 1.3;
      span {
        font-size: 14px;
        color: white;
      }
    }
  }
  .swiper-pagination-services {
    bottom: -8px;
    display: flex;
    /* flex-direction: column; */
    position: relative;
    justify-content: center;
  }
}</style
>>
