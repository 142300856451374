<template>
  <div>
    <h2 v-html="title" v-if="title"></h2>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        v-for="proposal in proposals"
        :key="proposal.proposalId"
      >
        <proposal-brand-card :proposal="proposal" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProposalBrandCard from "./ProposalBrandCard.vue";

export default {
  components: { ProposalBrandCard },
  name: "ProposalBrandGrid",
  props: {
    title: { type: String, required: false },
    proposals: { type: Array, required: true }
  }
};
</script>
