<template>
  <div class="home">
    <NewsAlert v-if="isAuthenticated" />
    <v-container
      v-if="
        isAuthenticated && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
      "
    >
      <CartInfoSlider />
    </v-container>

    <ServiceButtons
      v-if="!isAuthenticated"
      :title="$t('ServicesButtons.titleHomePage')"
    />

    <h1 class="d-sr-only">{{ title }}</h1>
    <p class="d-sr-only" v-text="description" />
    <v-container class="homepage-disclaimer pb-0">
      <p class="mb-0">{{ $t("homepage.priceDisclaimer") }}</p>
    </v-container>
    <v-container v-if="carousel.length > 0">
      <h2
        v-if="
          category.metaData.template_model &&
            category.metaData.template_model.CAROUSEL_ONE_TITLE
        "
        v-html="category.metaData.template_model.CAROUSEL_ONE_TITLE"
      ></h2>
      <ProposalSlider :proposals="carousel" />
    </v-container>
    <v-container v-if="carouselTwo.length > 0">
      <h2
        v-if="
          category.metaData.template_model &&
            category.metaData.template_model.CAROUSEL_TWO_TITLE
        "
        v-html="category.metaData.template_model.CAROUSEL_TWO_TITLE"
      ></h2>
      <ProposalSlider :proposals="carouselTwo" />
    </v-container>

    <v-container
      class="pb-0"
      v-for="proposal in headerProducts"
      :key="proposal.id"
    >
      <ProductListSlider
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
        :key="key"
      />
    </v-container>
    <v-container class="header-banner-container" v-if="headerBanner.length > 0">
      <h2
        v-if="
          category.metaData.template_model &&
            category.metaData.template_model.IPERAL_PROPOSAL_TITLE
        "
        v-html="category.metaData.template_model.IPERAL_PROPOSAL_TITLE"
      ></h2>
      <ProposalIperalGrid :proposals="headerBanner" />
    </v-container>
    <v-container
      v-if="sidebarBanner.length > 0 || footerBanner.length > 0"
      class="sidebar-banner"
    >
      <h2
        v-if="
          sidebarBanner.length > 0 &&
            category.metaData.template_model &&
            category.metaData.template_model.VENDOR_PROPOSAL_TITLE
        "
        v-html="category.metaData.template_model.VENDOR_PROPOSAL_TITLE"
      ></h2>
      <ProposalBrandGrid
        v-if="sidebarBanner.length > 0"
        :proposals="sidebarBanner"
        proposal-class="brand"
      />
      <h2
        v-if="
          footerBanner.length > 0 &&
            category.metaData.template_model &&
            category.metaData.template_model.FOOTER_PROPOSAL_TITLE
        "
        v-html="category.metaData.template_model.FOOTER_PROPOSAL_TITLE"
      ></h2>
      <ProposalSlider
        v-if="footerBanner.length > 0"
        :paginationClass="'swiper-pagination-footer'"
        :proposals="footerBanner"
      />
    </v-container>
    <v-container
      v-if="footerProducts.length > 0"
      class="footer-products"
      :key="key"
    >
      <h2
        v-if="
          footerProducts.length > 0 &&
            category.metaData.template_model &&
            category.metaData.template_model.FOOTER_BANNER_TITLE
        "
        v-html="category.metaData.template_model.FOOTER_BANNER_TITLE"
      ></h2>
      <ProductListSlider
        v-for="proposal in footerProducts"
        class="py-2"
        :key="proposal.id"
        :proposal="proposal"
        :title="proposal.descr"
        :mode="proposal.metaData.category_proposal_type.MODE"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>
    <v-container> </v-container>

    <ServiceButtons
      v-if="isAuthenticated"
      class="mb-5"
      :title="$t('ServicesButtons.titleHomePage')"
    />
  </div>
</template>
<script>
import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CartInfoSlider from "@/components/cart/CartInfoSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import ProposalIperalGrid from "@/components/proposal/ProposalIperalGrid.vue";
import ProposalBrandGrid from "@/components/proposal/ProposalBrandGrid.vue";
import ServiceButtons from "@/components/buttons/ServiceButtons.vue";
import NewsAlert from "@/components/news/NewsAlert.vue";

import { mapActions, mapGetters } from "vuex";
import { mapProposal } from "~/service/ebsn.js";
import get from "lodash/get";
import split from "lodash/split";
export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    ProductListSlider,
    ProposalSlider,
    ProposalIperalGrid,
    ProposalBrandGrid,
    CartInfoSlider,
    ServiceButtons,
    NewsAlert
  },
  data() {
    return {
      slides: {},
      key: 1
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    }
  },
  computed: {
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    },
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    }),
    ...mapProposal({
      carousel: "carousel",
      carouselTwo: "carousel-2",
      headerProducts: "header-products",
      headerBanner: "header-banner",
      sidebarBanner: "sidebar-banner",
      footerBanner: "footer-banner",
      footerProducts: "footer-products"
    })
  }
};
</script>
